import cssVars from 'css-vars-ponyfill';

cssVars({
	variables: {
		'primary': '22, 22, 172',
		'primary-dark': '17, 17, 125',
		'accent': '224, 53, 79',
		'accent-plus': '255, 255, 255',
	},
});
